const pageMaxWidth = '1328px';

const menuHeight = {
  mobile: 64,
  tablet: 64,
  desktop: 56,
  desktopLarge: 56,
};

const fontSizes = [12, 14, 18, 20, 24, 32, 48, 64, 96, 112, 128];
const lineHeights = [0.9, 1, 1.125, 1.25, 1.5];

const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
};

const radii = [0, 2, 4, 8];

const borders = [0, '1px solid', '2px solid'];

// Measures to prevent long-line texts
const measures = ['20em', '30em', '34em'];

// Refactoring theme constraints
const spacing = {
  unit: 16,
};

export const zIndexes = {
  nav: 9,
  navMobilePanel: 8,
  languageBar: 2,
};

export const typography = [
  {
    fontFamily: 'Graphik-Regular',
    sources: [
      {
        url: '/fonts/Graphik/variants/Graphik-Regular.woff2',
        format: 'woff2',
      },
      {
        url: '/fonts/Graphik/variants/Graphik-Regular.woff',
        format: 'woff',
      },
      {
        url: '/fonts/Graphik/variants/Graphik-Regular.ttf',
        format: 'truetype',
      },
      {
        url: '/fonts/Graphik/variants/Graphik-Regular.svg#Graphik-Regular',
        format: 'svg',
      },
      {
        url: '/fonts/Graphik/variants/Graphik-Regular.eot?#iefix',
        format: 'embedded-opentype',
      },
    ],
  },
  {
    fontFamily: 'Graphik-Medium',
    sources: [
      {
        url: '/fonts/Graphik/variants/Graphik-Medium.woff2',
        format: 'woff2',
      },
      {
        url: '/fonts/Graphik/variants/Graphik-Medium.woff',
        format: 'woff',
      },
    ],
  },
];

const theme = {
  fontSizes,
  fontSize: fontSizes, // @rod: I think this non-pluralised word seems more appropriated
  typography,
  lineHeights,
  fontWeights,
  radii,
  borders,
  measures,
  pageMaxWidth,
  spacing,
  menuHeight,
  zIndexes,
};

export default theme;
