import returningApplicantsRepository from './repository/returningApplicants';

export function createReturningApplicantFn({ getProspectType, sendToDataLayer }) {
  return () => {
    const applicantProspectType = getProspectType();

    if (applicantProspectType) {
      sendToDataLayer(applicantProspectType);
    }
  };
}

export default createReturningApplicantFn(returningApplicantsRepository);
