import { sendToGTM } from '@nubank/www-latam-commons/services/gtm';
import { cookieStorage } from '@nubank/www-latam-commons/utils/storage';
import { APPLICANT_COOKIE_NAME } from 'utils/cookies';

export function createReturningApplicantRepository(
  storage = cookieStorage,
  cookieName = APPLICANT_COOKIE_NAME,
  setOnDataLayer = sendToGTM,
) {
  return ({
    getProspectType() {
      return storage.get(cookieName);
    },

    setProspectType(prospectType, expirationDate) {
      storage.set(cookieName, prospectType, expirationDate);
    },

    sendToDataLayer(prospectType) {
      setOnDataLayer({ returning_applicant_prospect_types: prospectType });
    },
  });
}

export default createReturningApplicantRepository();
