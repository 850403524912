/* eslint-disable no-console */
import * as Sentry from '@sentry/node';
import React from 'react';
import App from 'next/app';
import { defaultPrimaryColorScheme } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import nuDSTheme from '@nubank/nuds-web/styles/theme';
import Router from 'next/router';
import { isType } from '@nubank/nuds-web/utils/isType/isType';

import WebsiteProvider from '@nubank/www-latam-commons/components/WebsiteProvider/WebsiteProvider';
import ImgGlobalStyle from '@nubank/www-latam-commons/styles/ImgGlobalStyles';
import addWebpSupportClass from '@nubank/www-latam-commons/utils/browserFeatures/webp/addWebpSupportClass';
import { persistMarketingURL } from '@nubank/www-latam-commons/utils/marketingUtils';
import { persistMGMParameters } from '@nubank/www-latam-commons/utils/mgmUtils';
import { queryStringToParameters } from '@nubank/www-latam-commons/utils/urlUtils';
import { compose } from '@nubank/www-latam-commons/utils/functionalUtils';
import printJoinUsMessage from '@nubank/www-latam-commons/utils/asciiart/printJoinUsMessage';
import { IGNORE_URLS } from '@nubank/www-latam-commons/utils/sentry';
import setReturningApplicantOnDataLayer from 'domains/prospect/setReturningApplicantOnDataLayer';
import theme from 'styles/theme';
import RouterLink from 'components/RouterLink/RouterLink';
import { DEFAULT_LOCALE } from 'utils/i18nUtils';
import { triggerOptimize } from 'tracking/optimize/triggerOptimize';
import * as experimentsUtils from 'utils/experiments';
import { userSession } from 'utils/userSession';

import { cmsService } from '../services/cms/cmsService';

import routes from './routes.json';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  ignoreErrors: [
    // Random plugins/extensions
    /top\.GLOBALS/,
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    /originalCreateNotification/,
    /canvas.contentDocument/,
    /MyApp_RemoveAllHighlights/,
    /http:\/\/tt\.epicplay\.com/,
    /Can't find variable: ZiteReader/,
    /Can't find variable: al_onAdViewRendered/,
    /hj is not defined/,
    /tgetT is not defined/,
    /jigsaw is not defined/,
    /ComboSearch is not defined/,
    /http:\/\/loading\.retry\.widdit\.com/,
    /atomicFindClose/,
    // Facebook borked
    /fb_xd_fragment/,
    // Tiktok
    /i18n\/pixel\/events/,
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    /bmi_SafeAddOnload/,
    /EBCallBackMessageReceived/,
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    /conduitPage/,
    // Google Translate
    /__gg__\.translateNotifyApiReady/,
    // others
    /function Error\(\) \{ \[native code\] \}/,
    /require is not defined/,
    /p\(branch-latest\)/,
    // Network Errors
    /Network request failed/,
    // /Failed to fetch/,
    /NetworkError/,
    // /TypeError: Failed to fetch/,
    /TypeError: NetworkError when attempting to fetch resource./,
    /TypeError: Cancelled/,
    /TypeError: cancelado/,
    /Illegal invocation/,
    /Non-Error promise rejection captured/,
    /A conexão de rede foi perdida/,
    /A conexão à internet parece estar desativada/,
    /Esgotou-se o tempo limite da solicitação/,
    /AbortError: The operation was aborted/,
    /chunk/,
    /^No error$/,
    /__show__deepen/,
    /Access is denied/,
    /anonymous function: captureException/,
    /Blocked a frame with origin/,
    /console is not defined/,
    /Error: AccessDeny/,
    /event is not defined/,
    /ibFindAllVideos/,
    /SecurityError/,
    /win\.document\.body/,
    /window\._sharedData\.entry_data/,
    /analytics\.tiktok\.com\/i18n\/pixel\/identify\.js/i,
    /cdn\.branch\.io\/branch-latest\.min\.js/i,
    'Branch SDK initialization failed, so further methods cannot be called',
  ],
  ignoreUrls: IGNORE_URLS,
});

// TODO: Remove it afeter we finish the first version of NuDSTheme
export const themeMergedWithNuDS = {
  ...nuDSTheme,
  ...theme,
};

export default class CustomApp extends App {
  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      locale: props.locale,
      messages: props.messages,
    };

    printJoinUsMessage('https://boards.greenhouse.io/nubank?gh_src=b58135231');

    // workaround to remove trailing slash at the end of href in static export
    // https://github.com/zeit/next.js/pull/3283
    if (typeof __NEXT_DATA__ !== 'undefined') {
      __NEXT_DATA__.nextExport = false;
    }
  }

  static async getInitialProps({ Component, router, ctx }) {
    try {
      const pageProps = Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {};

      const { query: { locale = DEFAULT_LOCALE }, asPath } = router;
      let messages = {};
      let cms = {};

      if (typeof window === 'undefined') {
        cms = await cmsService({
          locale,
          preview: asPath.includes('preview=true'),
          cache: !asPath.includes('cache=false'),
        });
      }

      if (typeof window === 'undefined') {
        messages = require(`../locale/${locale}.json`);
      }

      return {
        pageProps,
        locale,
        messages: { ...cms, ...messages },
        pathname: router.pathname,
      };
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async componentDidMount() {
    persistMarketingURL(document.location);

    compose(
      persistMGMParameters(),
      queryStringToParameters,
    )(document.location.search);

    setReturningApplicantOnDataLayer();

    addWebpSupportClass(document.querySelector('body'));

    triggerOptimize();

    Router.events.on('routeChangeComplete', triggerOptimize);

    userSession.initialize(sessionStorage);
  }

  render() {
    const {
      Component, pageProps, locale, messages,
    } = this.props;

    // TODO: Have a better way to extract this info in future
    const pageColorSchemeVariant = Object.values(routes).reduce((pageColorScheme, route) => {
      const routePathname = route.routes['pt-BR'];
      const hasRoutePageColorScheme = Boolean(route.pageColorScheme);
      const currentPagePathname = this.props.router.pathname;
      if (hasRoutePageColorScheme && routePathname === currentPagePathname) {
        return route.pageColorScheme;
      }
      return pageColorScheme;
    }, defaultPrimaryColorScheme);

    const availableExperiments = Object.values(experimentsUtils)
      .filter(value => isType('object', value))
      .filter(object => Object.keys(object).includes('id') && Object.keys(object).includes('variants'));

    const customPolyfills = [
      'Intl',
      'Intl.RelativeTimeFormat',
      'Intl.PluralRules.~locale.pt',
      'Intl.DateTimeFormat.~locale.pt',
      'Intl.NumberFormat.~locale.pt',
      'Intl.RelativeTimeFormat.~locale.pt',
      'Intl.RelativeTimeFormat.~locale.en',
    ];

    return (
      <>
        <ImgGlobalStyle />
        <WebsiteProvider
          locale={this.state?.locale || locale}
          messages={this.state?.messages || messages}
          theme={themeMergedWithNuDS}
          colorScheme={pageColorSchemeVariant}
          routerLinkComponent={RouterLink}
          availableExperiments={availableExperiments}
          customPolyfillFeatures={customPolyfills}
          enablePolyfill={false}
        >
          <Component {...pageProps} />
        </WebsiteProvider>
      </>
    );
  }
}
